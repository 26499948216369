import React from "react";
import "./header.scss";

const Header = (props) => {
  return (
    <header>
      <nav>
        <div className="container">
          <div className="content">
            <a className="brand" href="https://springjournals.net">
              <h1>Spring Journals</h1>
            </a>
            <ul>
              <li>
                <a href="https://springjournals.net">Home</a>
              </li>
              <li>
                <a href="https://springjournals.net/about">
                  About
                </a>
              </li>
              <li>
                <a href="https://springjournals.net/journals">
                  Journals
                </a>
              </li>
              <li>
                <a href="https://springjournals.net/authors-instruction">
                Author's Instruction
                </a>
              </li>
              <li>
                <a href="https://springjournals.net/publication-charges">
                  Publication Charges
                </a>
              </li>
              <li>
                <a href="https://springjournals.net/contact">
                  Contact us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
